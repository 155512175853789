.integrante-description-container-outer-div {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  width: 70vw;
  margin-top: 10vh;
  margin-bottom: 10vh;
}

.image-container-integrante-description {
  display: flex;
  width: 50vw;
  align-items: center;
}

.integrante-description-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-left: 30px;
}

.imagen-abogado {
  width: 100%; /* Make images responsive */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.8);
  margin-right: 30px;
}

.center-div {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping on small screens */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .integrante-description-container-outer-div {
    flex-direction: column;
    align-items: center;
    width: 90vw;
  }

  .image-container-integrante-description {
    width: 80vw;
    justify-content: center;
  }

  .integrante-description-container {
    margin-left: 0;
    margin-top: 20px;
  }

  .imagen-abogado {
    margin: 0 auto; /* Center image on small screens */
  }
}
