body {
    font-family: Arial, sans-serif;
    margin: 0;
    background-color: #f8f8f8;
}

.receipt-container {
    width: 600px;
    background-color: #ffffff;
    border: 1px solid #000;
    padding: 20px;
    margin: 0 auto;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.receipt-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.law-office {
    text-align: left;
}

.law-office h2 {
    font-size: 24px;
    margin: 0;
}

.law-office h3 {
    font-size: 20px;
    margin: 5px 0 0 0;
}

.receipt-title {
    text-align: center;
}

.receipt-title h1 {
    font-size: 32px;
    margin: 0;
}

.receipt-body {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.left-section, .right-section {
    width: 48%;
    border: 1px solid #000;
    padding: 10px;
    position: relative;
}

.info-block {
    margin-bottom: 40px;
}

.line {
    border-bottom: 1px solid #000;
    margin-bottom: 10px;
    height: 20px;
}

.account-section {
    margin-top: 20px;
}

.signature-block {
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: 90%;
}

.signature {
    margin-top: 30px;
}

.receipt-footer {
    border-top: 1px solid #000;
    padding-top: 10px;
    text-align: center;
    font-size: 12px;
}

.generar-pdf-button {
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--primary-color);
  transition: background-color 0.3s;
  margin-bottom: 6vh;
  width: 60%;
}


.generar-pdf-button:hover{
    background-color: rgb(63, 63, 207);

}