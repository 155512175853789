  .outer-div-sanivo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
  margin-top: 10vh;
}

.div-sanivo {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 90vw;
}

.san-ivo-imagen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
  min-width: 150px;
  width: 20vw;
  margin: 40px;
}

.titulo-funcaion-san-ivo{

}

.subtitulo-san-ivo{
    margin: 0px;
}

.parrafo-san-ivo{
  padding-left: 5vw;
  padding-right: 5vw;
  line-height: 1.8;

}

.decalogo-lista-div{
  display: flex;
  margin-left: 5vw;
  margin-right: 5vw;
}