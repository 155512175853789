.header-div {
  height: max-content;
}

.out-div-nav-bar {
  background-color: #2d3f6f;
  height: 60px;
}

.navigation-bar {
  height: 60px;
}

.navigation-bar {
  z-index: 3;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  left: 0;
  width: 100%;
  /* Ensure it extends full width */
  z-index: 1000;
  /* Ensure it stays on top of other elements */
  background-color: #2d3f6f;
}

/* Style the links inside the navigation bar */
.navigation-bar a {}

/* Change the color of links on hover */
.menu a:hover {}

.img-logo a:hover {}

/* Add a color to the active/current link */
.navigation-bar a.active {}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Vertically center the text */
  height: 60px;
  /* Adjust the height of the parent container as needed */
  width: 75%;
  padding-left: 10%;
  padding-right: 10%;
}

.menu-text {
  display: flex;
  align-items: center;
  color: white;
  font-family: merriweather;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
  height: 60px;
  line-height: 60px;
  overflow: hidden;
  padding: 0 1rem;
  padding-left: 2%;
  padding-right: 2%;
  margin-right: 0.3%;
  margin-left: 0.3%;
  /* Adds spacing between items */
  white-space: nowrap;
  /* Prevents text from wrapping */
  text-overflow: ellipsis;
  /* Truncates text with ellipsis if it overflows */
  max-width: 170px;
  min-width: 95px;
  /* Limits the width of each menu item */
}


.menu-text:hover {
  background-color: white;
  color: #2d3f6f;
  transition: background-color 0.3s ease;
}

.menu-text:hover .user-icon-navbar {
  color: var(--primary-color);
}

.menu-text:hover .whatsapp-icon-navbar {
  color: green;
}

header .nav-btn {
  visibility: hidden;
  opacity: 0;
  height: 0vh;
  z-index: -2;
}

.whatsapp-icon-navbar {
  color: white;
  text-align: center;
}

.user-icon-navbar {
  color: white;
  text-align: center;
}

.user-icon-navbar:hover {
  color: #2d3f6f;
  text-align: center;
}

.whatsapp-icon-menu {
  font-size: 1.8rem;
  display: flex;
  height: 60px;
  align-items: center;
}

.whatsapp-icon-menu:hover {
  color: green;
}

@font-face {
  font-family: merriweather;
  src: url(../../public/Merriweather/Merriweather-Bold.ttf);
}

.nav-btn-div {
  width: 0%;
  height: 0%;
}

@media only screen and (max-width: 768px) {
  .menu {
    padding-left: 5%;
    padding-right: 5%;
  }

  .menu-text {
    font-size: 1rem;
    /* Slightly smaller font for better fit */
    padding: 0 0.5rem;
    /* Adjust spacing for smaller screens */
    max-width: 120px;
    /* Further limit width on smaller screens */
  }
}

@media only screen and (max-width: 1024px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
    background-color: rgba(243, 243, 243, 0);
    border: none;
    width: 50%;
    padding-top: 0px;
    font-size: x-large;
    z-index: 9;
    color: #fdfdfd;
  }

  .menu .responsive_nav {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 5vh;
    padding: none;
    height: 100vh;
  }

  .nav-btn .nav-close-btn {
    padding: 0px;
    margin: 0px;
  }

  .menu {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    padding-right: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 10;
  }

  .navigation-bar a {
    font-size: 1rem;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    font-size: 0.1rem;
    max-height: 100vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: #2d3f6f;
    transition: 0.5s;
    transform: translateY(-100vh);
    width: 100vw;
  }

  header .responsive_nav {
    transform: none;
  }

  nav .nav-close-btn {
    display: flex;
    justify-content: center;
    text-align: center;
    align-self: center;
    position: absolute;
    top: 1.5rem;
    justify-content: center;
    padding: 0px;
    margin: 0px;
  }
}

@media only screen and (max-width: 500px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
    color: rgb(255, 255, 255);
    background-color: #2d3f6f;
    padding-right: 0px;
    padding-left: 0px;
  }

  .whatsapp-icon-navbar {
    font-size: 30px;
  }

  .user-icon-navbar {
    font-size: 40px;
  }

  nav .nav-close-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 1.5rem;
    /* Adjust or remove as needed */
  }

  .menu {
    background-color: #2d3f6f;
    height: 100vh;
    width: 100vw;
    text-align: center;
    z-index: 10;
  }

  .nav-btn-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }

  .menu .responsive_nav {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100vw;
    padding-top: 5vh;
    padding: none;
  }

  .menu {
    padding-left: 0px;
    padding-right: 0px;
  }

  .menu-text {
    transition: 0.5s;
    width: 100vw;
  }

  .menu-text:hover {
    background-color: white;
  }
}