.youtube-video {
  height: 60vh;
}


@media (max-width: 768px) {
    .youtube-video {
        height: 30vh;
      }
      
}