.outer-div-mp {
  display: flex;
  text-align: center;
  align-items: center;
  height: 30vh;
}

.button-pagar-mp {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  text-align: center;
  height: 100px;
  width: 350px;
}
