.boton-de-descarga-pdf {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: var(--primary-color);
    color: white;
    width: 30%; /* Initial width */
    height: 5vh; /* Initial height, consider using padding instead for better responsiveness */
    border-radius: 50px;
    border: solid;
    border-width: 2px;
    border-color: var(--primary-color);
    transition: 0.5s;
    cursor: pointer;
    font-weight: bold;
    padding: 10px; /* Add padding to ensure the button looks good on small screens */
  }
  
  .boton-de-descarga-pdf:hover {
    background-color: white;
    color: var(--primary-color);
    font-weight: bolder;
    transform: scale(1.1); /* Use transform instead of scale for better browser support */
  }
  
  /* Media Query for smaller screens */
  @media (max-width: 768px) {
    .boton-de-descarga-pdf {
      width: 50%; /* Increase width on smaller screens */
      /* Optional: Adjust font size, padding, and other properties if needed */
    }
  }
  
  /* Media Query for very small screens */
  @media (max-width: 480px) {
    .boton-de-descarga-pdf {
      width: 80%; /* Further increase width for very small screens */
      /* Consider adjusting font-size, padding, and possibly height to ensure good usability */
    }
  }
  