.footer-container-div {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #2d3f6f;
  color: white;
  padding: 2rem 0;
  font-size: 1.2rem; /* Adjust the font size to match the design */
  box-shadow: 0px -20px 20px rgba(0, 0, 0, 0.15); /* Add shadow to the top outside */
}

.footer -content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 5%;
}

.footer-section h2 {
  display: flex;
  color: #f2f2f2; /* Lighter text color for headings */
  margin-bottom: 1rem;
}

.footer-section {
  flex: 1 1 200px;
  min-width: 200px;
}
.info-footer {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.center-social-icons {
  display: flex;
}
.social-icons {
  display: flex;
  justify-content: center;
  text-align: center;
  list-style: none;
  padding: 0;
  margin-top: 1rem;
  align-items: center;
  width: 15%;
}

.icon-size-large {
  font-size: 1.8rem;
}
.social-icons a {
  color: white;
  text-decoration: none;
  display: flex;
}

.footer-section ul {
  padding-left: 0; /* Remove padding from list */
}

.footer-section ul li {
  margin-bottom: 0.5rem; /* Space out the list items */
  margin-top: 0.5rem;
}

.go-to-top {
  text-align: center;
  margin-top: 1rem;
}

.go-to-top a {
  color: rgb(255, 255, 255); /* Give the link a distinct color */
  text-decoration: none;
  font-weight: bold;
}

.fa-phone,
.fa-envelope,
.fa-map-marker-alt {
  font-size: 10rem;
  vertical-align: middle;
}

.icons-socials {
  display: flex;
  width: 50%;
}

/* Adjust the layout for smaller screens */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }
  .footer-section {
    text-align: center; /* Center the content on small screens */
    margin: 1rem 0;
  }

  .social-icons {
    
    justify-content: center;
    width: 50px;
    height: 100px;
    flex-direction: column;
    
  }
  .icons-socials{
    margin: 8px;
  }

  .go-to-top {
    margin-top: 1.5rem;
  }
}
