.vinculados-section-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
    margin-top: 10vh;
}

.vinculados {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80vw;
    text-align: center;
}

.vinculados-p {}

.outside-div-lista-de-vinculados {
    display: flex;
    flex-direction: row;
    padding-top: 3vh;
}

.list-of-vinculados {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ind-marca-div {

    display: flex;
    flex-direction: column;
    width: 40%;
    height: 30%;
    margin-bottom: 3vh;
    border: solid;
    border-color: var(--primary-color);
    padding: 2vh;
}

.vinculados-p{
    font-weight: 400;
}