.outside-div-netriup {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  cursor: pointer;
}
.netriup-info {
  display: flex;
  flex-direction: row;
  width: 50vw;
}
.netriup-frase {
  width: fit-content;
}
.netriup-nombre {
  width: fit-content;
  font-weight: bold;
  cursor: pointer;
  padding-left: 10px;
}
