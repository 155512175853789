* {
  width: 100%;
}

html, body {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}
.outer-div-website .home{
  max-width: 90%;
}
.root {
  width: 100%;

}
:root {
  --primary-color: #2b3d6b;
}
h1 {
  font-family: merriweather;
}


@font-face {
  font-family: merriweather;
  src: url(../public/Merriweather/Merriweather-BlackItalic.ttf);
}

.outer-div-website {
  display: flex;
  align-content: center;
  flex-direction: column;
  align-items: center;  
}
