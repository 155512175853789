* {
  justify-content: center;
}

.container-difusion {
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 70%;
  height: fit-content;
  margin-bottom: 10vh;
  margin-top: 10vh;
}

.image-and-text-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.parrafo-superior-sobre-nosotros {
  font-family: merriweather;
  margin-top: 5%;
  margin-bottom: 5%;
  font-size: 1.8rem;
}

.imagen-sobre-nosotros {
  width: 40%;
}



@font-face {
  font-family: merriweather;
  src: url(../../public/Merriweather/Merriweather-Bold.ttf);
}

@media only screen and (max-width: 500px) {
  .parrafo-superior-sobre-nosotros {
    font-size: 1.2rem;
  }
  .container-imagen-sobre-nosotros {
    height: 30vh;
    width: auto;
  }
  .imagen-sobre-nosotros {
    width: auto;
  }
}
