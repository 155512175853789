.outside-div-individual-office {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sides-office {
  display: flex;
  flex-direction: column; /* Adjust this for larger screens */
  align-items: center;
  width: 80vw; /* Adjusted for more consistent spacing */
  text-align: center;
  margin: 5% 0;
}

.izquierda-side-ind-off, .derecha-side-ind-off {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 50%; /* Equal width for both sides */
}

.horarios-div-outside, .maps-div {
  width: 100%; /* Use full width for internal components */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imagen-container {
  display: flex;
  flex-wrap: wrap; /* Allow images to wrap on smaller screens */
  justify-content: center;
  width: 80vw; /* Consistent with .sides-office */
}

.indiv-image {
  width: 30%; /* Adjust image width for responsiveness */
  margin: 1%; /* Add some margin around images */
  object-fit: cover; /* Ensure images cover the area well without distortion */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .sides-office {
    flex-direction: column;
    width: 95vw; /* Adjust width for very small screens */
  }

  .izquierda-side-ind-off, .derecha-side-ind-off {
    width: 100%; /* Full width for better utilization of space */
  }

  .horarios-div-outside, .maps-div {
    align-items: center;
  }

  .imagen-container {
    width: 95vw; /* Full width for image container */
  }

  .indiv-image {
    width: 90%; /* Larger images on small screens */
    margin: 5% 0; /* More space around images */
  }

  .horarios-div, .dias-div, .horarios-div-horas {
    display: flex;
    flex-direction: column; /* Stack days and hours vertically */
    width: 100%; /* Use the full width for each */
    align-items: center; /* Center align the content */
  }
}

/* Ensure legibility and accessibility */
.titulo-oficina, .descripcion-oficina, .horarios-title, .dia-horario {
  margin: 5px 0; /* Add some margin for spacing */
}

.titulo-oficina {
  font-size: 1.5rem; /* Adjust title font size for readability */
}

.descripcion-oficina, .horarios-title, .dia-horario {
  font-size: 1rem; /* Adjust text font size for consistency */
}
