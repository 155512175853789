.titulo-oficina {
  color: rgb(0, 0, 0);
  font-family: merriweather;
}

.descripcion-oficina {
  font-size: 1.2rem;
  font-weight: 300;
  font-family: merriweather;
}

.horarios-cuadro {
  display: flex;
  flex-direction: row;
}

.horarios-div {
  display: flex;
  flex-direction: row;
  border: solid;
  border-radius: 10px;
  width: 30vw;
  padding: 10px;
  margin-bottom: 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.8);
}

.derecha-side-ind-off {
  display: flex;
  flex-direction: column;
}
.horarios-title {
  font-size: 1.4rem;
  font-family: merriweathe;
  font-weight: bold;
}

.dias-div {
  display: flex;
  width: 30%;
  flex-direction: column;
}

.dia-horario {
  font-size: 1.2rem;
  font-family: merriweathe;
  font-weight: 300;
}

.horarios-div-horas {
  display: flex;
  width: 30%;
  flex-direction: column;
}

.location-sedes .virtual-location {
  width: 100%;
}
@font-face {
  font-family: merriweather;
  src: url(../../public/Merriweather/Merriweather-Italic.ttf);
}
