.marquee-container {
    margin-top: 8vh;
    margin-bottom: 2vh;
    position: relative;
    overflow: hidden;
    width: 200%;
    height: 50px; /* Adjust based on your text size */
    mask-image: linear-gradient(to right, transparent, black 10%, black 90%, transparent);
    -webkit-mask-image: linear-gradient(to right, transparent, black 10%, black 90%, transparent);
}

.marquee {
    display: flex;
    flex-direction: row;
    animation: marquee-animation 30s linear infinite;
    white-space: nowrap;
}

.marquee--reverse {
    animation-direction: reverse;
}

.marquee__group {
    display: flex;
    flex-shrink: 0;
    width: 100%;
}

.marquee-text {
    font-size: 1.5rem; /* Adjust for visibility */
    color: #333; /* Text color */
    margin: 0 4vw; /* Adjust for spacing */
    display: inline-block;
}

@keyframes marquee-animation {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}
