.servicio-description-div {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
}

.outside-div-servicio {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  margin-bottom: 100px;
  margin-top: 10vh;
}
.opciones-dentro-de-servicios {
  display: flex;
  margin-top: 2%;
  margin-bottom: 4%;
  width: 80%;
}

.opciones-servicios {
  display: flex;
  flex-direction: column;
}

.opciones-servicios {
  display: flex;
  flex-direction: column;
  width: 80vw;
}

.titulo-servicio {
  font-size: 2.5rem;
  text-align: center;
}
.descripcion-servicio {
  width: 80%;
  text-align: center;
  font-weight: 400;
}
.descripcion-inferior-servicio {
  width: 80%;
  text-align: center;
  font-weight: 550;
}

.botones-opciones-servicios {
  width: 30%;
  font-family: merriweather;
  padding: 3%;
  border-radius: 90px;
  margin-left: 5%;
  margin-right: 5%;
  transition: 0.2s;
  border: solid;
  border-width: 1px;
  color: black;
  font-size: 1.2rem;
  font-weight: 800;
  background-color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
}

.botones-opciones-servicios:hover {
  cursor: pointer;
  border-color: #2d3f6f;

  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.8);
}

.selectedOption {
  border: solid;
  border-width: 1px;
  border-color: #2d3f6f;

  color: #2d3f6f;
  transition: 0.5s;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.8);
}

.mensaje-inferior-servicio{
  
  font-size: 1.8rem;
  text-align: center;
}

.descripcion-servicio {
  white-space: pre-wrap; /* or 'pre-line' */
}
@media (max-width: 768px) {
  .opciones-dentro-de-servicios {
    display: flex;
    flex-direction: column;
    margin-top: 2%;
    margin-bottom: 4%;
    width: 80%;
    align-items: center;
  }
  .botones-opciones-servicios {
    width: 60vw;
    margin: 5%;
  }
  .titulo-servicio{
    font-size: 1rem;
  }
}

@font-face {
  font-family: merriweather;
  src: url(../../public/Merriweather/Merriweather-Italic.ttf);
}
