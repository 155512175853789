.container-home {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center;
  width: 100%;
}

@font-face {
  font-family: merriweather;
  src: url(../../public/Merriweather/Merriweather-Italic.ttf);
}
.container {
  width: 70%; /* Adjust to desired width */
  margin: auto;
  margin: 6rem;
}

.titulo {
  font-size: 2.5rem; /* Bigger font-size for the title */
  text-align: center;
  margin-top: 1.5rem 0;
  margin-bottom: 0rem;
}

.texto {
  text-align: justify; /* Align the text to justify as per the design */
  font-size: 1.8rem;
  margin-bottom: 2rem;
  font-family: merriweather;
}

.titulo-principal {
  margin-top: 0px;
  color: #2d3f6f;
  margin-bottom: 0px;
}

.subtitulo {
  font-size: 1.5rem;
  font-weight: lighter;
  color: #2d3f6f;
  margin: 0px;
  font-weight: bold;
}

.container-tres-imagenes {
  display: flex;
  justify-content: space-between; /* This will space out the images evenly */
  margin-bottom: 2rem;
  max-width: 70%;
}

.info-sede {
  display: flex;
  flex-direction: column;
  height: 350px;
}
.sedes {
  cursor: pointer;
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  width: 21vw;
  margin: 0 1%;
  transition: 0.5s;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.8);
}
.sedes:hover {
  scale: 1.03;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.8);
}

.image-container-sedes {
  height: fit-content;
}
.imagenes-sedes {
  width: 100%; /* Full width within the container */
  height: 350px; /* Fixed height */
  object-fit: cover; /* Cover the area without stretching */
  border-radius: 10px;
}

.sedes-title {
  color: #2d3f6f;
  text-align: center;
  margin-bottom: 5px;
}

.location-sedes {
  display: flex;
  align-items: center;
  font-family: merriweather;
}

.parrafo-sedes {
  text-align: initial;
  padding: 0 1rem; /* Add padding */
  font-family: merriweather;
  font-size: 1.2rem;
  width: fit-content;
}

.location-sedes-icon-div {
  width: 20%;
  font-size: 1.8rem;
  color: #2d3f6f;
}
.sedes-subtitle {
  margin-top: 0px;
}

.sede-virtual-outside-div {
  width: 70%;
  border-radius: 10px;
  margin-top: 5vh;
  margin-bottom: 15vh;
}

.sede-virtual {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  transition: 0.5s;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.8);
}

.sede-virtual:hover {
  scale: 1.03;
}

.sede-virtual-imagen {
  height: 30vh;
  width: 97%;
  object-fit: cover;
  padding: 10px;
  border-radius: 30px;
}

.virtual {
  text-align: center;
}

.virtual-location-address {
  display: flex;
}
.virtual-location {
  width: 40%;
  display: flex;
}
/* Responsive considerations */
@media (max-width: 768px) {
  .container-tres-imagenes {
    flex-direction: column;
    max-width: 90%;
  }
  .container {
    width: 80%;
    margin-top: 3vh;
    margin-bottom: 3vh;
  }

  .sedes {
    width: 90%; /* Full width for mobile */
    margin: 1rem auto; /* Center sedes on mobile */
  }
  .imagenes-sedes {
    height: 200px; /* Adjust height for mobile if needed */
  }
  .titulo-principal {
    font-size: 2rem;
    width: 100%;
  }

  .subtitulo {
    font-size: 1.2rem;
  }
  .texto {
    font-size: 1.2rem;
    text-align: justify;
  }
  .sede-virtual-outside-div {
    width: 90%;
  }
  .sede-virtual-imagen {
    width: 90%;
  }
}
