

.container-de-todo {
  display: flex;
  justify-content: center;
  align-items: center; /* Align items for better vertical alignment */
  width: 100%; /* Ensure full width to accommodate smaller screens */
}

.servicio-container {
  display: flex;
  flex-direction: column; /* Change to column layout for better control */
  margin-top: 4vh;
  width: 80%; /* Adjust width for better scaling */
  justify-content: center;
  margin-bottom: 4vh;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  transition: 0.5s;
  overflow: hidden; /* Maintain border-radius effect */
  height: fit-content;
  max-height: 30vh;
}

.imagen-container {
  width: 100%; /* Full width for images */
  display: flex;
  align-items: center;
  overflow: hidden; /* Keep overflow hidden */
}

.servicio {
  display: flex;
  flex-direction: row; /* Use row for larger screens */
  font-family: merriweather;
  max-height: auto; /* Adjust max height for responsiveness */
}

.servicio-container:hover {
  scale: 1.03;
  cursor: pointer;
}

.imagen-servicio {
  width: 100%; /* Full width */
  height: 100%; /* Maintain aspect ratio */
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.informacion-container {
  padding: 2vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  user-select: none;
  width: 100%; /* Full width for text container */
}
.imagen-container{
  height: auto;
}
/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .servicio {
    flex-direction: column; /* Stack image and text vertically */
  }

  .imagen-container, .informacion-container {
    width: 100%; /* Ensure full width usage */
    height: 100%;
  }

  .titulo-servicio, .descripcion-servicio, .precio-servicio {
    font-size: 2rem; /* Adjust font size for readability */
  }
  .servicio-container{
    height: fit-content;
    max-height: none;
  }
  .imagen-servicio{
    max-height: 30vh;
    border-bottom-left-radius: 0px;
  }
  .informacion-container{
    padding: 0px;
  }
}

.visible {
  animation: slideUpFadeIn 0.7s ease-out forwards;
}
