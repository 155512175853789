.outer-container-integrante {
  display: flex;
}
.container-centrador {
  display: flex;
  justify-content: center;
  font-family: merriweather;
}

.integrantes-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  margin-top: 10vh;
}

.row {
  display: flex;
  margin-bottom: 0px;
  gap: 10%;
  width: fit-content;
  min-width: 50vw;
}

.nombre-integrante {
  font-size: 1.4rem;
}

.imagen-integrante-div {
  display: flex;
  max-height: 60%;
}

.imagenes-integrantes {
  object-fit: cover;
  max-height: 200px;
  object-position: 50% 30%;
  width: 100%; /* Make sure the image takes the full width of the container */
  height: auto; /* Maintain aspect ratio */
  min-height: 200px;
}

.each-integrante {
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  transition: 0.3s;
  justify-content: space-around;
  text-align: center;
  margin-bottom: 10vh;

}
.nombre-integrante-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem; /* Set a fixed height */
  overflow: hidden; /* Hide overflow text */
}

.each-integrante:hover {
  user-select: auto;
  scale: 1.03;
  cursor: pointer;
}
.titulo-u-profesion-wrapper{
    align-items: center;
    display: flex;
    height: 5rem;
    justify-content: center;
    overflow: hidden;
}
.titulo-o-profesion {
  font-size: 1.2rem;
  font-weight: 600;
  padding-left: 2px;
  padding-right: 2px;
}

.titulo-miembros {
  display: flex;
  text-align: center;
  font-size: 40px;
  height: 10vh;
}

.row-only-one {
  display: flex;
  text-align: center;
  max-width: 20vw;
}

.integrante-titulo-nombre {
  font-size: 3rem;
}

.informacion-integrante {
  font-weight: 300;
  font-size: 1.8rem;
  font-family: merriweather;
}
.profesion-div {
  display: flex;
  flex-direction: row;
}

@font-face {
  font-family: merriweather;
  src: url(../../public/Merriweather/Merriweather-Italic.ttf);
}

/* Responsive considerations */
@media (max-width: 768px) {
  .row {
    flex-direction: column;
  }
  .row-only-one {
    max-width: none;
  }
  .titulo-miembros {
    font-size: 2rem;
  }
  .titulo-largo {
    font-size: 1.5rem;
  }

  .each-integrante {
    height: fit-content;
    margin-bottom: 5vh;
    height: 100%;
    max-height: 80vh;
  }

  .nombre-integrante {
    font-size: 1.5rem;
    margin: 0px;
  }
  .titulo-o-profesion {
    font-size: 1.2rem;
  }
  .integrantes-container {
    width: 90%;
  }

  .imagenes-integrantes {
    width: 100%;
    max-height: 100%;
  }
  .row {
    width: 100%;
  }

  @keyframes slideUpFadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .visible {
    animation: slideUpFadeIn 0.7s ease-out forwards;
  }
}
