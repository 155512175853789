.outer-div-usuario {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
  margin-bottom: 100px;
}

.div-usuario {
  text-align: center;
  width: 80vw;
  border: solid;
  border-color: var(--primary-color);
  border-radius: 20px;
  margin: 20px;
  padding: 20px;
  font-weight: bold;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.8);

}

#username {
  width: 50%;
}

#password {
  width: 50%;
}

.boton-iniciar {
  margin: 10px;
  padding: 10px;
  width: 50%;
  border-radius: 100px;
  border: solid;
  border-width: 1px;
  background-color: white;
  font-weight: bold;
}

.boton-iniciar:hover{
  background-color: var(--primary-color);
  color: white;
  cursor: pointer;
}
.outside-table-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.table-div{
  width: 95%;
  white-space: nowrap;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

.table-div Td{
  padding: 10px;
  text-align: center;
}

.actions-db{
  width: 30px;
}

.outside-div-sistema-interno{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inside-div-sistema-interno{
  width: 95%;
}
.titulo-sistema-interno{
  text-align: center;
}

.menu-navegador-sistema-interno{
  display: flex; 
}

.boton-navegador{
  padding: 10px;
  margin: 15px;
}

.boton-navegador:hover{
  background-color: var(--primary-color);
  color: white;
  cursor: pointer;
}

.table-div th{
  padding: 10px;
}
.tabla-rows{
  background-color: var(--primary-color);
  color: white;
}

.tbody-rows tr:nth-child(even) {
  background-color: #f0f0f0; /* Light gray for even rows */
}

.tbody-rows tr:nth-child(odd) {
  background-color: #d0d0d0; /* Darker gray for odd rows */
}

.outside-div-seccion-nuevo-cliente{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inside-div-seccion-nuevo-cliente{
  border-radius: 30px;
  border: solid;
  border-width: 3px;
  border-color: black;
  padding: 30px;
  width: 80vw;
  margin-bottom: 50px;
}

.outside-div-seccion-ius{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.inside-div-seccion-ius{
  border-radius: 30px;
  border: solid;
  border-width: 3px;
  border-color: black;
  padding: 30px;
  width: 80vw;
  margin-bottom: 50px;
}


.search-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
}

.search-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 8vh;
}

.search-input,
.search-select {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-input::placeholder {
  color: #888;
}

.search-select {
  cursor: pointer;
}

.search-button,
.view-all-button {
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.view-all-button{
  margin-top: 4vh;
}
.search-button {
  background-color: var(--primary-color);
}

.search-button:hover {
  background-color: #0056b3;
}

.view-all-button {
  background-color: #28a745;
}

.view-all-button:hover {
  background-color: #218838;
}
