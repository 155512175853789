.ind-blog{
    cursor: pointer;
}

.ind-blog:hover{
    color: var(--primary-color);
    
}

.boton-elegir-tema{
    background-color: white;
    color: var(--primary-color);
    margin: 10px;
    border-radius: 50px;
    border: solid;
    border-color: var(--primary-color);
    font-weight: bold;
    padding: 10px;
    min-width: fit-content;
}

.boton-elegir-tema:hover{
    background-color: var(--primary-color);
    color: white;
    cursor: pointer;
}

.boton-elegir-tema.selected{
    background-color: var(--primary-color);
    color: white;
    cursor: pointer;
}
.botones-blog{
    display: flex;
    flex-direction: row;
}

.ind-blog-result{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

}

.parrafo-blog{
    width: 80vw;
    line-height: 1.8;

    margin-left: 30px;
    margin-right: 30px;
}
@media only screen and (max-width: 1024px) {
    .botones-blog{
        display: flex;
        flex-direction: column;
    }
}
