.outside-oficina-virtual {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100vh;
  width: 100vw;
}
.oficina-virtual-titulo {
  display: flex;
  text-align: center;
  width: 100vw;
}

.calendly-inline-widget {
  height: 700px !important;
}

@media only screen and (max-width: 500px) {
  .outside-oficina-virtual {
    margin-top: 10vh;
}
}
