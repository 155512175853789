.slideshow {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 60vh;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.15); /* Add shadow to the bottom outside */
}

.imagen-banner {
  width: 100%;
  height: 70vh;
  object-fit: cover;
}

.slide-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(45, 63, 111, 1);
  color: white;
  border: none;
  border-radius: 50%; /* Makes the button rounded */
  cursor: pointer;
  padding: 10px; /* Adjust padding to make the button smaller */
  font-size: 16px; /* Adjust font size as needed */
  z-index: 2;
  width: 30px; /* Set a fixed width */
  height: 30px; /* Set a fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.slide-button.left {
  left: 10px;
}

.slide-button.right {
  right: 10px;
}

/* Optional: Add hover effect for buttons */
.slide-button:hover {
  background-color: rgba(255, 255, 255, 0.9);
  color: rgb(45, 63, 111);
}

.slideshow-dots {
  text-align: center;
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.dot.active {
  background-color: #717171;
}
