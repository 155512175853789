.servicios-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
  padding: 20px;
}

.scrolling-marquee-text-outside-div {

  -webkit-mask-image: linear-gradient(to right, transparent, black 10%, black 90%, transparent);

}

@media (max-width: 768px) {
  .servicios-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}